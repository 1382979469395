<template>
	<common-table
			:itemScope="['status']"
			:headers="headers"
			:listData="listData"
			@changePage="changePage"
			:loading="loading"
			:btnAction="true"
			@search="search"
			@remove="remove"
			@save="save"
			@edit="edit"
			ref="ct"
			:leftConfig="leftConfig"
			:rightConfig="rightConfig"
			:listDeploy="listDeploy"
			pageTitle="短信通知"
	>
		<template v-slot:status="{ item }">
			<w-switches
					inset
					label=""
					color="var(--v-primary-base)"
					@change="updateStatus(item)"
					v-model="item.status" hide-details dense/>
		</template>
	</common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/systemManage/notice/message-notice.js';

export default {
	mixins: [commonCurd],
	data() {
		return {
			api,
			leftConfig: [
				{type: 'input', label: '输入模版名称', property: 'description'}
			],
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			headers: [
				{text: '模板名称', value: 'description'},
				{text: '触发方式', value: 'trigger'},
				{text: '内容', value: 'content', width: '750px'},
				{text: '状态', value: 'status'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "模板名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'outid',
						connector: {
							props: {
								required: false,
								label: "外部模板"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'trigger',
						connector: {
							props: {
								required: true,
								label: "触发方式"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'condition',
						connector: {
							props: {
								required: false,
								label: "公式条件"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'status',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 12,
						subassembly: "VTextarea",
						property: 'content',
						connector: {
							props: {
								required: false,
								row: 8,
								outlined: true,
								label: "模板内容"
							}
						}
					}
				]
			}
		}
	},
	methods: {
		updateStatus(item) {
			this.axios.post(this.api.updateStatus, {sqlid: item.sqlid, status: !item.status}).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success('更新成功');
				}
			})
		}
	},
	mounted() {
		this.axios.post(this.select_data, {keyname: 'msgtrigger'}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.listDeploy.row[2].connector.props.items = res.data[0].values
			}
		})
	}
}
</script>

<style scoped lang="scss">

</style>
